<template>
  <el-button
    v-if="!hiddenIfEmpty || src"
    class="button"
    :disabled="disabled"
    icon="el-icon-download"
    @click="handleDownload"
  >
    {{ $t(`button.download`) }}
  </el-button>
</template>

<script>
export default {
  name: 'DownloadImage',
  props: {
    src: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: 'image',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hiddenIfEmpty: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleDownload() {
      this.$utils.serveImageForDownload(this.src);
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  font-size: 0.14rem;
  max-width: 1.4rem;
  margin-top: 0.15rem;
  padding: 0.12rem 0.2rem;
}
</style>
