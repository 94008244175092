<template>
  <div id="ai-services_aiu_image-instance-segmentation">
    <service-template
      :run="run"
      :run-success="runSuccess"
      :run-error="runError"
      :loading="loading"
      :run-disabled="currentExample === null && uploadedImage === null"
      :icon="require('@/assets/images/icons/services/header/SEG.svg')"
      type="image"
      service="aiu"
    >
      <template v-slot:header>
        {{ $t('services.imageInstanceSegmentation.header') }}
      </template>
      <template v-slot:left>
        <carousel>
          <el-image
            v-for="(example, index) in examples"
            :key="index"
            :slot="`slide-${index}`"
            :class="{ 'swiper-selected': currentExample === index }"
            :src="example"
            fit="cover"
            class="example"
            @click="() => onExampleClick(index)"
          />
        </carousel>
        <upload
          :auth="isAuthenticated()"
          :file-size="2"
          :example-file="examples[currentExample]"
          :disabled="loading"
          :handle-remove="onUploadRemove"
          :handle-upload="onUploadSuccess"
        />
      </template>
      <template v-slot:right>
        <generated-result
          :step="2"
          :service-name="$t('services.imageInstanceSegmentation.header')"
          :loading="loading"
        >
          <div
            v-if="result"
            class="result"
          >
            <template v-if="result.code === 'success'">
              <el-image
                v-if="result.code === 'success'"
                :src="imageSrc"
                :preview-src-list="[imageSrc]"
                :z-index="9001"
              />
              <div class="download-image">
                <download-image :src="imageSrc" />
              </div>
            </template>
            <span v-else>
              {{ $t('error[\'Oops Sorry! Couldn’t find anything\']') }}
            </span>
          </div>
        </generated-result>
      </template>
    </service-template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ServiceTemplate from '@/views/pages/AIServices/components/ServiceTemplate/Index.vue';
import GeneratedResult from '@/views/pages/AIServices/components/GeneratedResult/Index.vue';
import DownloadImage from '@/components/DownloadImage/Index.vue';
import Carousel from '@/components/Carousel/Index.vue';
import Upload from '@/components/Upload/Index.vue';

export default {
  name: 'ImageInstanceSegmentation',
  components: {
    DownloadImage,
    Carousel,
    GeneratedResult,
    ServiceTemplate,
    Upload,
  },
  data: () => ({
    currentExample: null,
    uploadedImage: null,
    loading: false,
    result: null,
  }),
  computed: {
    ...mapGetters(['isAuthenticated']),
    imageSrc() {
      return `data:image/png;base64, ${this.result.base64_img}`;
    },
    examples() {
      return [
        `${this.$consts.publicPath}/examples/AIU/SEG/car_on_road.jpg`,
        `${this.$consts.publicPath}/examples/AIU/SEG/giraffe.jpg`,
        `${this.$consts.publicPath}/examples/AIU/SEG/laptop_and_cup.jpg`,
        `${this.$consts.publicPath}/examples/AIU/SEG/people_walking_in.jpg`,
        `${this.$consts.publicPath}/examples/AIU/SEG/person_street_skating.jpg`,
        `${this.$consts.publicPath}/examples/AIU/SEG/phone.jpg`,
        `${this.$consts.publicPath}/examples/AIU/SEG/tennis.jpg`,
        `${this.$consts.publicPath}/examples/AIU/SEG/trucks.jpg`,
      ];
    },
  },
  watch: {
    currentExample() {
      this.result = null;
    },
    uploadedImage() {
      this.result = null;
    },
  },
  methods: {
    async run() {
      this.loading = true;
      const formData = new FormData();
      if (this.uploadedImage) {
        formData.append('file', this.uploadedImage);
      } else {
        const imageResponse = await this.$api.aiu.fetchImage(
          this.examples[this.currentExample],
        );
        formData.append('file', imageResponse.data);
      }
      return this.$api.aiu.instanceSegmentation.postInstanceSegmentation(formData);
    },
    runSuccess(result) {
      this.loading = false;
      this.result = result.data;
    },
    runError() {
      this.loading = false;
    },
    onExampleClick(index) {
      if (this.loading) {
        return;
      }

      this.currentExample = index;
      this.uploadedImage = null;
      this.result = null;
    },
    onUploadRemove() {
      this.result = null;
      this.currentExample = null;
      this.uploadedImage = null;
    },
    onUploadSuccess(file) {
      this.uploadedImage = file;
    },
  },
};
</script>

<style lang="scss">
#ai-services_aiu_image-instance-segmentation {
  .download-image {
    text-align: right;
  }
}
</style>
<style lang="scss" scoped>
#ai-services_aiu_image-instance-segmentation {
  background: $background-secondary;

  .example {
    height: 100%;
    cursor: pointer;
  }
}
</style>
